<template>
  <Combobox v-model="selectedItem" as="div" :disabled="disabled">
    <ClientOnly>
      <div class="relative flex flex-col">
        <div
          class="w-full"
          :class="{ 'order-last': isAbove, 'order-first': !isAbove }"
        >
          <ComboboxInput
            ref="targetInput"
            class="h-field w-full rounded-md border bg-white px-4 focus:outline-none sm:rounded-none"
            :display-value="(item) => disabled ? 'Wird geladen...' : decodeHtml((item as LabelValue).label)"
            @change="query = $event.target.value"
            @click="reset"
          />
          <ComboboxButton
            v-slot="{ open }"
            class="absolute inset-y-0 right-0 border bg-white px-1 focus:outline-none"
          >
            <div class="flex w-4 items-center justify-center sm:w-6 lg:w-8">
              <IconsUp
                class="w-2 -rotate-180 fill-g7-blue transition sm:w-4"
                :class="{ 'rotate-0': open }"
              />
            </div>
          </ComboboxButton>
        </div>

        <div :class="{ 'order-first': isAbove, 'order-last': !isAbove }">
          <ComboboxOptions
            v-if="filteredItems.length > 0"
            ref="targetOptions"
            :class="{ '-translate-y-full': isAbove }"
            class="absolute z-20 max-h-60 w-full overflow-auto border-x border-b bg-white py-1 text-sm normal-case shadow-lg focus:outline-none"
            @scroll="loadMore"
          >
            <ComboboxOption
              v-for="item in filteredItems"
              :key="item.value"
              v-slot="{ active, selected }"
              :value="item"
              as="template"
            >
              <li
                :class="[
                  'relative cursor-default select-none px-4 py-2',
                  active ? 'bg-g7-blue text-white' : 'text-g7-blue',
                ]"
              >
                <span :class="['block truncate', selected && 'font-semibold']">
                  {{ decodeHtml(item.label) }}
                </span>

                <span
                  v-if="selected"
                  :class="[
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                    active ? 'fill-white' : 'fill-g7-blue',
                  ]"
                >
                  <IconsCheck class="size-5" aria-hidden="true" />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </div>
      </div>
    </ClientOnly>
  </Combobox>
</template>

<script setup lang="ts">
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import type { Ref } from "vue";
import { ref, computed, watch, onBeforeUnmount } from "vue";
import { decodeHtml } from "./../../composables/converter";
import type { LabelValue } from "~~/types/form";

const props = defineProps<{
  items: Array<LabelValue>;
  disabled?: boolean;
}>();

const isAbove = ref(false);
const targetOptions = ref(null);
const limit: Ref<number> = ref(20);
const size = 20;

const query: Ref<string> = ref("");
const selectedItem: Ref<LabelValue | undefined> = ref();

const targetInput = ref(null);
const storedValue: Ref<string> = ref("");

const onClickOutside = (e: MouseEvent) => {
  if (
    targetInput.value?.el &&
    !targetInput.value?.el.contains(e.target as Node)
  ) {
    if (targetInput.value.el.value === "") {
      targetInput.value.el.value = storedValue.value;
    }
    document.removeEventListener("click", onClickOutside);
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("click", onClickOutside);
});

function reset() {
  storedValue.value = targetInput.value?.el.value;
  targetInput.value.el.value = "";
  document.addEventListener("click", onClickOutside);
}

const filteredItems = computed(() => {
  let item = [];
  query.value === ""
    ? (item = props.items)
    : (item = props.items.filter((item) => {
        return item.label.toLowerCase().includes(query.value.toLowerCase());
      }));
  return item.slice(0, limit.value);
});

function loadMore() {
  if (targetOptions.value) {
    const element = targetOptions.value;
    if (
      element.el.scrollHeight - element.el.scrollTop <=
        element.el.clientHeight + 24 &&
      limit.value <= props.items.length
    ) {
      limit.value += size;
    }
  }
}
watch(
  () => targetOptions.value?.el,
  async () => {
    isAbove.value = false;
    await nextTick();
    isAbove.value = isBoundingBottom(targetOptions.value?.el);
  }
);

watch(selectedItem, () => {
  query.value = "";
});

watch(query, () => {
  limit.value = size;
});
</script>
